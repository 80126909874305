import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {VmmsSharedModule} from "vmms-common";
import {ToastrModule} from "ngx-toastr";
import {apiPrefixInterceptorProvider} from "./_interceptors/api-prefix.interceptor";
import {apiHeaderInterceptorProvider} from "./_interceptors/api-header.interceptor";
import {apiErrorInterceptorProvider} from "./_interceptors/api-error.interceptor";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    VmmsSharedModule,
    ToastrModule.forRoot({
      timeOut: 3500,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
  ],
  providers: [
    apiPrefixInterceptorProvider,
    apiHeaderInterceptorProvider,
    apiErrorInterceptorProvider,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
